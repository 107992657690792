
<template>
  <div class="item">
    <div class="img-content">
      <img class="hotel-img" :src="item.img ? item.img : img" />
      <img class="hotel-img-tag" v-if="item.bussType == 1" :src="jia_xiang_hui" alt="">
      <!-- <div class="hotel-tag" v-if="item.bussType == 1">
								<span>嘉享积分</span>
							</div> -->
    </div>
    <div class="item-center">
      <div class="center-top">
        <p @click="jumpDetails(item)">{{ item.jdmc }}</p>
        <el-rate :colors="['#FF9524', '#FF9524', '#FF9524']" :disabled="true" :value="Number(item.category)"></el-rate>
      </div>
      <div class="center-center">
        <p style="max-width: 500px;text-align: left;">{{ item.address }}</p>
        <span style="cursor: pointer;" @click="getMapInfo(item)">查看地图</span>
        <span v-if='item.bussType == 2 || item.corpGroup == 1' class="icon-xieyi">协议</span>
        <!-- <span v-if='item.bussType==1 ' class="icon-xieyi" style="background-color: #fd712f;">自签</span> -->
        <span v-if='item.bussType == 1' class="icon-xieyi" style="background-color: #fd712f;">嘉享协议</span>
        <span v-if='item.bussType == 2' class="icon-xieyi" style="background-color: #fd712f;">企业托管</span>
        <span v-if='item.corpGroup == 1' class="icon-xieyi" style="background-color: #fd712f;">集团托管</span>
      </div>
      <div class="row-line" v-show="item.distanceMsg">
        <!-- distanceMsg -->
        <span>{{ item.distanceMsg }}</span>
      </div>
      <div class="htFacilities" v-if="!!item.facilities && item.facilities.length > 0">
        <div v-for="(tagItem, idx) in item.facilities" :key="idx + '_htfacilities'" class="facilitiesTag">
          <span>{{ tagItem }}</span>
        </div>
      </div>
      <!-- <div class="center-bottom" v-if="!!item.facilities && item.facilities.length > 0">
        <span v-for="(iconItem, index) in item.facilities" :key="index">
          <span v-if="facility[iconItem]" class="iconYs">{{ facility[iconItem]['title'] }}</span>
          <el-tooltip v-if="facility[iconItem]" effect="light" :content="facility[iconItem].title"
            placement="bottom-start">
            <span class="iconYs" :class="facility[iconItem].icon"></span>
          </el-tooltip>
        </span>
      </div> -->
    </div>
    <div class="item-right">
      <div class="right-top">
        <p class="p1">￥{{ item.price }}</p>
        <p class="p2">起</p>
      </div>
      <div class="btn disable" @click="disableDetails(item)" v-if="tripType == 1 && item.filterHotel == 1">企业屏蔽！</div>
      <div class="btn" @click="jumpDetails(item)" v-else>查看详情</div>
      <!-- TODO 在因公模式下item.filterHotel==1 会屏蔽酒店  -->
      <!-- <div class="btn"  @click="jumpDetails(item)">屏蔽酒店</div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'TmcObtHtCnListItem',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      jia_xiang_hui: require('@/assets/image/ht/jia_xiang_hui.png'),
      img: require('@/assets/image/banner/a2.png'),
      facility: { //酒店包含的服务
        152: {
          title: "收费电脑",
          icon: "iconfont yj-icon-diannao_o"
        },
        153: {
          title: "免费电脑",
          icon: "iconfont yj-icon-diannao_o"
        },
        227: {
          title: "大堂提供上网电脑",
          icon: "iconfont yj-icon-diannao_o"
        },
        243: {
          title: "租借笔记本电脑",
          icon: "iconfont yj-icon-diannao_o"
        },
        160: {
          title: "健身中心",
          icon: "iconfont yj-icon-jianshen"
        },
        22: {
          title: "免费班车接送",
          icon: "iconfont yj-icon-car-full"
        },
        242: {
          title: "免费接机服务",
          icon: "iconfont yj-icon-jh_hbh"
        },
        269: {
          title: "收费接机服务",
          icon: "iconfont yj-icon-jh_hbh"
        },
        197: {
          title: "免费停车",
          icon: "iconfont yj-icon-tingchechang"
        },
        198: {
          title: "收费停车",
          icon: "iconfont yj-icon-tingchechang"
        },
        225: {
          title: "中餐厅",
          icon: "iconfont yj-icon-canpinhui-xican"
        },
        226: {
          title: "西餐厅",
          icon: "iconfont yj-icon-canpinhui-xican"
        },
        233: {
          title: "日餐厅",
          icon: "iconfont yj-icon-canpinhui-xican"
        },
        234: {
          title: "中西自助餐厅",
          icon: "iconfont yj-icon-canpinhui-xican"
        },
        235: {
          title: "旋转餐厅",
          icon: "iconfont yj-icon-canpinhui-xican"
        },
      },
    };
  },
  mounted() {

  },
  computed: {
    ...mapState({
      htCnParams: (state) => state.htCn.htCnParams,
      htCnQuery: (state) => state.htCn.htCnQuery, //
    }),
    tripType() {
      /* 默认当前因公因私*/
      return this.htCnQuery.tripType
    },
  },
  watch: {
    item(n) {
      console.log('facilities =', n.facilities);
    }
  },
  methods: {
    ...mapMutations({
      SAVE_HOTELINFO: 'htCn/SAVE_HOTELINFO',
    }),
    jumpDetails(item) {
      item.checkInDate = this.htCnParams.checkInDate
      item.checkOutDate = this.htCnParams.checkOutDate
      let hotelItem = {
        checkInDate: this.htCnParams.checkInDate,
        checkOutDate: this.htCnParams.checkOutDate,
        jdid: item.jdid,
        cityId: this.htCnParams.cityId
      }
      this.SAVE_HOTELINFO(item)
      let routeUrl = this.$router.resolve({
        name: 'HtCnDetails',
        query: hotelItem,
      })
      window.open(routeUrl.href, '_blank')

    },
    getMapInfo(item) {
      this.$emit('getMapInfo', item)
    },
    disableDetails() {
      this.$alert('根据贵司规定，不可开放预订！', {
        confirmButtonText: '确定',
      });

    },
  },
};
</script>

<style lang="scss" scoped>
$bcolor: #006bb9;

.item {
  width: 100%;
  margin-top: 10px;
  height: 180px;
  background-color: #fff;
  // background-color: pink;
  display: flex;
  position: relative;

  .img-content {
    width: 180px;
    height: 180px;
    overflow: hidden;
    position: relative;

    .hotel-img {
      width: 180px;
      height: 180px;
      object-fit: cover;
    }

    .hotel-tag {
      position: absolute;
      top: 0;
      left: 0;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #45516d;
      border-radius: 0 0 6px 0;

      span {
        color: #f5efe3;
        font-size: 12px;
      }
    }

    .hotel-img-tag {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;

    }
  }

  .item-center {
    margin-left: 20px;

    .center-top {
      margin-top: 15px;
      display: flex;
      align-items: center;

      p {
        font-size: 18px;
        font-weight: bold;
        margin-right: 20px;

      }

      p:hover {
        color: red;
        cursor: pointer;
      }

      img {
        width: 16px;
        height: 14px;
      }
    }

    .center-center {
      margin-top: 10px;
      display: flex;
      align-items: center;

      p {
        color: #808080;
        font-size: 12px;
      }

      span {
        font-size: 12px;
        color: #007aff;
        margin-left: 15px;
      }

      .icon-xieyi {
        padding: 2px 10px;
        color: #FFFFFF;
        background-color: #007aff;
        border-radius: 2px;
      }
    }

    .row-line {
      text-align: left;
      margin-top: 10px;

      span {
        font-size: 13px;
        color: #888;
      }
    }

    .htFacilities {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 10px;
      overflow: hidden;
      max-height: 20px;

      .facilitiesTag {
        padding: 0 2px;
        border-radius: 3px;
        border: 1px solid rgba(0, 122, 255, 0.5);
        box-sizing: border-box;
        margin-right: 4px;
        margin-bottom: 2px;
        height: 20px;
        display: flex;
        align-items: center;

        span {
          font-size: 11px;
          color: #555;
        }
      }

    }

    .center-bottom {
      margin-top: 22px;
      display: flex;

      .iconYs {
        height: 25px;
        border: 1px solid #ff9f39;
        padding: 2px;
        box-sizing: border-box;
        color: #ff9f39;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }

  .item-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 20px;
    bottom: 20px;

    .right-top {
      display: flex;
      align-items: center;
      // justify-content: flex-end;
      justify-content: center;

      .p1 {
        color: #007aff;
        font-size: 24px;
        font-weight: bolder;
      }

      .p2 {
        font-size: 12px;
        color: #007aff;
      }
    }

    .btn {
      width: 115px;
      height: 40px;
      background-color: #007aff;
      // border-radius: 10px;
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      cursor: pointer;
    }

    .disable {
      background-color: #ccc;
      color: white;
    }
  }
}
</style>