<template>
	<div>
		<el-skeleton style="width: 860px" :loading="loading" animated :throttle="500">
			<template slot="template">
				<div class="skelet" style="padding: 20px">
					<el-skeleton-item variant="h3" style="width: 100%; height: 40px" />
				</div>
				<div class="skelet" style="margin-top: 20px">
					<div class="flex between" style="padding: 20px; margin-top: 20px">
						<el-skeleton-item variant="image" style="width: 20%; height: 40px" />
						<el-skeleton-item variant="h3" style="width: 60%; height: 30px" />
					</div>
				</div>
				<div class="skelet" style="margin-top: 20px">
					<div class="flex between" style="padding: 20px; margin-top: 20px">
						<el-skeleton-item variant="h3" style="width: 20%; height: 40px" />
						<el-skeleton-item variant="h3" style="width: 80%; height: 40px" />
					</div>
				</div>
				<div class="skelet" style="margin-top: 20px">
					<div class="flex between" style="padding: 20px; margin-top: 20px">
						<el-skeleton-item variant="h3" style="width: 20%; height: 30px" />
						<el-skeleton-item variant="h3" style="width: 80%; height: 30px" />
					</div>
				</div>
				<div class="skelet" style="margin-top: 20px">
					<div class="flex between" style="padding: 20px; margin-top: 20px">
						<el-skeleton-item variant="h3" style="width: 20%; height: 30px" />
						<el-skeleton-item variant="h3" style="width: 80%; height: 30px" />
					</div>
				</div>
			</template>
			<template>
				<div class="htcn-list" v-show="searchList.total !== 0">
					<hotelItem class="item" v-for="item in searchList.list" :key="item.jdid" :item="item"
						@getMapInfo="getMapInfo" />
				</div>
				<div class="second-like" v-show="secondList.total != void 0 && secondList.total !== 0">
					<span class="iconfont yj-icon-fire" style="color: red" />
					<span class="title">您可能需要的酒店</span>
				</div>
				<div class="htcn-list" v-show="secondList.total != void 0 && secondList.total !== 0">
					<hotelItem class="item" v-for="item in secondList.list" :key="item.jdid" :item="item"
						@getMapInfo="getMapInfo" />
				</div>
				<el-empty style="margin-bottom: 70px" v-show="searchList.total == 0" description="未找到合适的酒店，请重新搜索"></el-empty>
			</template>
		</el-skeleton>
	</div>
</template>

<script>

import htCnListItem from './htCnListItem.vue';
export default {
	components: {
		hotelItem: htCnListItem
	},
	props: {
		searchList: {
			type: Object,
			default() {
				return {};
			}
		},
		secondList: {
			type: Object,
			default() {
				return {};
			}
		},
		loading: {
			type: Boolean,
		},
	},
	data() {
		return {

		}
	},
	methods: {
		//点击地图提示信息
		getMapInfo(item) {
			this.$emit('getMapAddress', item)
		}
	},
}
</script>

<style scoped lang="scss">
.htcn-list {
	width: 100%;
	background-color: #eef1f6;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}

.second-like {
	display: flex;
	align-items: center;
	padding: 0 10px;
	.title {
		display: inline-block;
		margin-left: 10px;
		font-size: 17px;
		font-weight: 600;
	}
}
</style>
